import _ from 'underscore'
import string from 'underscore.string'
import $ from 'jquery'
import 'chosen.jquery'
import 'selectize'
import '../vendors/jquery.timeago'
import moment from 'moment-timezone'
import Backbone from 'hs-backbone'
import 'backbone.marionette'
import { Class } from 'jsface'
import Handlebars from 'handlebars'
import 'jquery.noty'
import 'jquery.fancybox'
import 'ccbcc-plugin'
import Controller, { State } from './controller'
import Constants from './constants'
import LocalStorage from './localStorage'
import SessionStorage from './sessionStorage'
import Keyboard from './keyboard'
import { createPopper } from '@popperjs/core'
import { sanitizeHTML } from '@common/utils/sanitizeHTML'

const MODAL_HASH = '#modal'

/**
 *  Naming Conventions:
 *      HS - The global used for everything
 *          Sub-sections of HS
 *          Models - Backbone.js Models - this is where the data is held to render a page
 *          Views - Backbone.js Views
 *          Controllers - This is made up of singletons/classes used to manage events, data etc
 *          Utils - Functions/Classes used to make common takes easier like generating random string, ajax, data manipulation IE underscore.js
 *          Plugins - Plugins and their wrappers that are used to genrate the UI
 *          Classes - Classes in the raw before being constructed and turned into plugins
 **/
if (!HS) {
  /**
   *  @name       HS
   *  @namespace  namespace of namespaces - Everything is part of this.
   *  @see        HS.Classes
   *  @see        HS.Controller
   *  @see        HS.Utils
   **/
  var HS = {}
  /**
   * @name        HS.Classes
   * @namespace   Classes/Prototypes namespace for HS
   * @type        {Object}
   **/
  HS.Classes = {}
  /**
   * @name        HS.Plugins
   * @namespace   Namespace section for plugins
   * @see         State
   * @type        {Object}
   **/
  HS.Plugins = {}
  /**
   * @name        HS.Models
   * @namespace   Models is made up of Backbone Models.
   * @type        {Object}
   * @requires    Backbone
   **/
  HS.Models = {}
  /**
   * @name        HS.Views
   * @namespace   Views is made of Backbone Views and nothing else.
   * @type        {Object}
   * @requires    Backbone
   **/
  HS.Views = {}
  /**
   * @name        HS.Controller
   * @namespace   Controller namespace for HS - Contains objects used to manage the application
   * @see         HS.Controller.PubSub
   * @see         HS.Controller.StateMachine
   * @type        {Object}
   **/
  HS.Controller = Controller
  /**
   * @name        HS.Utils
   * @namespace   Utils namespace for HS - Contains functions used to help accomplish common tasks that aren't part of a typical object
   * @see         HS.Utils.Controller
   * @type        {Object}
   **/
  HS.Utils = {}
}

HS.Utils.String = string

// Backbone sends this with all ajax requests. It can be changed via a response.
// See the `CsrfModel` for more info about this.
HS.csrfToken = $("meta[name='csrf-token']").attr('content')

HS.Utils.Controller = Controller
HS.stack = Controller.StateMachine.escapeStack
/**
 *
 * @param dropdownEl    .dropdown parent element containing [data-toggle=dropdown] and .dropdown-menu
 * @param name          name in Action Stack
 * @param onOpen        callback when opened
 * @param onClose       callback when closed
 */
HS.Utils.dropdownEscapeSetup = function (dropdownEl, name, onOpen, onClose) {
  name = 'Dropdown:' + name
  var $dropdownEl = $(dropdownEl)
  $dropdownEl
    .on('show.bs.dropdown', function (e) {
      if (e.namespace === 'bs.dropdown') {
        HS.Controller.PubSub.publish('User:Dropdown:CloseAll')
      }
    })
    .on('shown.bs.dropdown', function (e) {
      if (e.namespace === 'bs.dropdown') {
        if (onOpen && typeof onOpen === 'function') {
          onOpen()
        }

        HS.stack.closed(name)
        HS.stack.opened(name, function () {
          if ($dropdownEl.hasClass('open')) {
            $dropdownEl.find('[data-toggle=dropdown]').click().blur()
          }
        })

        // This calls a state that may not be registered depending on user
        // settings.
        if (hsGlobal.features.isKbShortcutsEnabled) {
          HS.Controller.StateMachine.changeState(
            'KeyboardGlobal',
            'Dropdown',
            null
          )
        }
      }
    })
    .on('hidden.bs.dropdown', function (e) {
      if (e.namespace === 'bs.dropdown') {
        if (onClose && typeof onClose === 'function') {
          onClose()
        }
        HS.stack.closed(name)

        // This calls a state that may not be registered depending on user
        // settings.
        if (hsGlobal.features.isKbShortcutsEnabled) {
          HS.Controller.StateMachine.changeState('KeyboardGlobal', 'Open', null)
        }
      }
    })
    .data('deferEscapeHandling', true)
}

HS.Utils.datepickerEscapeSetup = function (
  datepickerEl,
  name,
  onOpen,
  onClose
) {
  name = 'Datepicker:' + name
  datepickerEl = $(datepickerEl)
  datepickerEl
    .on('show', function (e) {
      if ($.isFunction(onOpen)) {
        onOpen()
      }
      HS.stack.closed(name)
      HS.stack.opened(name, function () {
        datepickerEl.removeClass('open')
        HS.stack.closed(name)
      })
    })
    .on('hide', function (e) {
      if ($.isFunction(onOpen)) {
        onClose()
      }
      //note: hide gets called from keydown, so if you close it synchronously, the escape stack will trigger again, since it currently listens for keyup. :/ - bjones
      setTimeout(function () {
        HS.stack.closed(name)
      }, 300)
    })
}

/**
 *
 * @param chosenSelectEl    .dropdown parent element
 * @param name          name in Action Stack
 * @param onOpen        callback when opened
 * @param onClose       callback when closed
 */
HS.Utils.chosenEscapeSetup = function (chosenSelectEl, name, onOpen, onClose) {
  $(chosenSelectEl)
    .on('liszt:showing_dropdown', function (e) {
      HS.Controller.PubSub.publish('Chozen:display', e)
      HS.stack.opened(name, function () {
        $('#assign_select_chzn .chzn-drop').css('display', 'none')
        $('a:first').focus()
        $('body').click()
        HS.stack.closed(name)
      })
      _.isFunction(onOpen) && onOpen()
    })
    .on('liszt:hiding_dropdown', function () {
      // when closed by anything other than body click, chosen has a phantom body click handler, so after closing, next time anything is clicked, this callback will fire.
      // Prevent onClose handler from firing again
      if (
        $(this).siblings('.chzn-container').find('.chzn-drop').is(':visible')
      ) {
        HS.stack.closed(name)
        _.isFunction(onClose) && onClose()
      }
    })
}

/**
 *
 * @param chosenSelectEl    .dropdown parent element
 * @param name          name in Action Stack
 */
HS.Utils.chosenCssTransition = function (chosenSelectEl) {
  var $select = $(chosenSelectEl)
  var $dropdown = $select.siblings('.chzn-container').find('.chzn-drop')
  var timer
  $dropdown.addClass('chzn-drop__animated')
  $select
    .on('liszt:showing_dropdown', function () {
      // It needs a split sec before showing
      clearTimeout(timer)
      timer = setTimeout(function () {
        $dropdown.addClass('open-forced open-transition')
      }, 50)
    })
    .on('liszt:hiding_dropdown', function () {
      clearTimeout(timer)
      $dropdown.removeClass('open-forced open-transition')
    })
}

/**
 *
 * @param selectizeEl   <select> element containing the selectize instance
 *
 * Destroys the selectize instance and clears the cache so next time this same element
 * is initialized with a different set of options, it won't have any elements by default.
 */
HS.Utils.selectizeDestroy = function (selectizeEl) {
  var selectize = selectizeEl[0].selectize
  if (selectize) {
    selectize.clear()
    selectize.clearCache()
    selectize.clearOptions()
    selectize.renderCache = {}
    selectize.destroy()
  }
}

HS.Utils.String.equalsIgnoreCase = function (str1, str2) {
  if (_.isString(str1) && _.isString(str2)) {
    return str1.toUpperCase() == str2.toUpperCase()
  }
  return false
}

HS.Utils.String.encodeUriValue = function (string) {
  return encodeURIComponent(string)
}

HS.Utils.String.decodeUriValue = function (string) {
  return decodeURIComponent(string)
}

HS.Utils.String.encodeUri = function (string) {
  return encodeURI(string)
}

HS.Utils.String.decodeUri = function (string) {
  return decodeURI(string)
}
HS.Utils.String.possessive = function (text) {
  if (!text) {
    return ''
  }

  var lastLetter = text.charAt(text.length - 1)

  if (lastLetter === 's') {
    return text + "'"
  }

  if ('you' == text.toLowerCase()) {
    return text + 'r'
  }

  if ('it' == text.toLowerCase()) {
    return text + 's'
  }

  return text + "'s"
}
HS.Utils.String.escape = function (string) {
  var escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
  }
  function escapeChar(chr) {
    return escape[chr] || '&amp;'
  }
  return string.replace(/[&<>"'`]/g, escapeChar)
}

//taken from http://phpjs.org/functions/similar_text/
HS.Utils.String.similar_text = function (first, second, percent) {
  if (
    first === null ||
    second === null ||
    typeof first === 'undefined' ||
    typeof second === 'undefined'
  ) {
    return 0
  }

  first += ''
  second += ''

  var pos1 = 0,
    pos2 = 0,
    max = 0,
    firstLength = first.length,
    secondLength = second.length,
    p,
    q,
    l,
    sum

  for (p = 0; p < firstLength; p++) {
    for (q = 0; q < secondLength; q++) {
      for (
        l = 0;
        p + l < firstLength &&
        q + l < secondLength &&
        first.charAt(p + l) === second.charAt(q + l);
        l++
      );
      if (l > max) {
        max = l
        pos1 = p
        pos2 = q
      }
    }
  }

  sum = max

  if (sum) {
    if (pos1 && pos2) {
      sum += this.similar_text(first.substr(0, pos1), second.substr(0, pos2))
    }

    if (pos1 + max < firstLength && pos2 + max < secondLength) {
      sum += this.similar_text(
        first.substr(pos1 + max, firstLength - pos1 - max),
        second.substr(pos2 + max, secondLength - pos2 - max)
      )
    }
  }

  if (!percent) {
    return sum
  } else {
    return (sum * 200) / (firstLength + secondLength)
  }
}

//parses email address out of emails formatted Help Scout <helpscout@helpscout.com>
HS.Utils.String.parseEmailFromCopy = function (emailStr) {
  var parsed = HS.Utils.String.parseEmailAndName(emailStr)
  if (parsed) {
    return parsed.email
  }
  return emailStr
}

/**
 * Given a string in the format `Some Body <some@body.com>`, parse the name and email address.
 * If the input string does not match this format, return `undefined`
 * @param emailString
 * @return {{name: *, email: *}}
 */
HS.Utils.String.parseEmailAndName = function (emailString) {
  if (emailString && typeof emailString === 'string') {
    var match = emailString.match(/(.*?)<(.*?)>/)
    if (!match || match.length < 3) return

    return {
      name: string.trim(match[1]),
      email: string.trim(match[2]),
    }
  }
}

HS.Utils.String.isValidEmail = function (string) {
  // should work: message+masterfile@crmondemand.biz
  // should work: bsteiner@discount-drugmart.com
  return /[a-z0-9!#$%&'*+\/\=?\^_`{|}~\-]+(?:\.[a-z0-9!#$%&'*+\/\=?\^_`{|}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?/i.test(
    string
  )
}

HS.Utils.String.getJsonFromQuery = function (query) {
  var data = query.split('&')
  var result = {}
  for (var i = 0; i < data.length; i++) {
    var item = data[i].split('=')
    result[item[0]] = item[1]
  }
  return result
}
/**
 * Escape a string to be used in a javascript command.
 */
HS.Utils.String.jsEscape = function (string) {
  if (string) {
    return string
      .trim()
      .replace(/\\/g, '\\\\')
      .replace(/"/g, '&quot;')
      .replace(/'/g, "\\'")
  }
  return ''
}

HS.Utils.String.numberStringToBoolean = function (string) {
  return Boolean(Number(string))
}

/**
 * Emoji string handling
 */
;(function () {
  // This matches roughly 90.5% of 4000+ tested emojis
  var regex = new RegExp(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g)

  function containsEmojis(string) {
    return regex.test(string)
  }

  function stripEmojis(string) {
    var stripped = string.replace(regex, '')
    if (stripped !== string) {
      // Stripping the emojis will leave multiple spaces (before & after spaces).
      // This will replace multiple consecutive spaces with a single space.
      return stripped.replace(/\s{2,}/g, ' ')
    }

    return stripped
  }

  HS.Utils.String.containsEmojis = containsEmojis
  HS.Utils.String.stripEmojis = stripEmojis
})()
;(function (window, doc, undef) {
  HS.Utils.injectJs = function (src, cb, attrs, timeout, err, internal) {
    var isFileReady = function (readyState) {
      // Check to see if any of the ways a file can be ready are available as properties on the file's element
      return (
        !readyState ||
        readyState == 'loaded' ||
        readyState == 'complete' ||
        readyState == 'uninitialized'
      )
    }

    var firstScript = doc.getElementsByTagName('script')[0],
      readFirstScript = function () {
        if (!firstScript || !firstScript.parentNode) {
          firstScript = doc.getElementsByTagName('script')[0]
        }
      }

    var script = doc.createElement('script'),
      done,
      i

    timeout = timeout || 1e4

    script.src = src

    // Add our extra attributes to the script element
    for (i in attrs) {
      script.setAttribute(i, attrs[i])
    }

    // cb = internal ? executeStack : ( cb || noop );

    // Bind to load events
    script.onreadystatechange = script.onload = function () {
      if (!done && isFileReady(script.readyState)) {
        // Set done to prevent this function from being called twice.
        done = 1
        cb()

        // Handle memory leak in IE
        script.onload = script.onreadystatechange = null
      }
    }

    // 404 Fallback
    window.setTimeout(function () {
      if (!done) {
        done = 1
        // Might as well pass in an error-state if we fire the 404 fallback
        cb(1)
      }
    }, timeout)

    // Inject script into to document
    // or immediately callback if we know there
    // was previously a timeout error
    readFirstScript()
    err
      ? script.onload()
      : firstScript.parentNode.insertBefore(script, firstScript)
  }
})(window, document)

HS.Utils.Function = new Class({
  async: function (funcfn, context, data) {
    setTimeout(function () {
      fn.call(context, data)
    }, 0)
  },
  noop: function () {
    void 0
  },
  bind: function (func, context) {
    func.bind(context)
  },
})

/**
 *
 * @type {Class}
 * @requires    HS.Utils
 * @requires    Class
 * @requires    jQuery - Event related methods
 * @public
 * @static
 * @class Singleton Event Utils
 * @property    {Boolean}  $singleton Is this a singleton class
 */
HS.Utils.Event = new Class(function () {
  return {
    /**
     * @private
     * @field
     * @static
     * @inner
     */
    $singleton: true,
    /**
     * @type {Function}
     * @contructs HS.Utils.Event
     * @function
     * @static
     * @private
     * @inner
     */
    main: function () {},
    /**
     * @type {Function}
     * @contructs HS.Utils.Event
     * @requires jQuery
     * @params {Event} evt Regular jQuery Event
     * @description stop default action and propagai
     * @function
     * @static
     * @public
     * @inner
     */
    silence: function (evt) {
      if (evt) {
        evt.stopPropagation()
        evt.preventDefault()
      }
    },
    /**
     * @type {Function}
     * @contructs HS.Utils.Event
     * @requires jQuery
     * @params {Event} evt Regular jQuery Event
     * @description stops default action and propagation
     * @function
     * @static
     * @public
     * @inner
     */
    silenceNow: function (evt) {
      if (evt) {
        evt.stopImmediatePropagation()
        evt.preventDefault()
      }
    },
    /**
     * @type {Function}
     * @contructs HS.Utils.Event
     * @requires jQuery
     * @params {Event} evt Regular jQuery Event
     * @description stops default action and immediate propagation
     * @function
     * @static
     * @public
     * @inner
     */
    silenceAll: function (evt) {
      if (evt) {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()
      }
    },
    /**
     * @type {Function}
     * @contructs HS.Utils.Event
     * @requires jQuery
     * @params {Event} evt Regular jQuery Event
     * @description Triggers jquery handlers and triggers fake click
     * @function
     * @static
     * @public
     * @inner
     */
    triggerAll: function ($el, evt) {
      $el.triggerHandler(evt)
      $el.trigger(evt)
    },
  }
})

/**
 * @type        {Class}
 * @requires    HS.Utils
 * @requires    Class
 * @requires    jQuery - Ajax related methods
 * @class       Singleton
 * @public
 * @static
 * @property    {Boolean}  $singleton Is this a singleton class
 */
HS.Utils.Ajax = new Class(function () {
  $(document).ajaxComplete(function (event, request, settings) {
    if (!request || !request.getResponseHeader) return
    var appVersion = request.getResponseHeader('App-Version')
    if (appVersion && appVersion !== hsGlobal.appVersion) {
      HS.Controller.PubSub.publish('reload-hs-app', appVersion)
      hsGlobal.appVersion = appVersion
    }
  })

  function getUrl(pParts) {
    if ($.isArray(pParts)) {
      return hsGlobal.path + pParts.join('/') + '.json'
    }
    return pParts
  }
  function handleRedirect(xhr) {
    if (xhr && xhr.status == 301) {
      var location = xhr.getResponseHeader('X-HS-REDIRECT')
      if (location !== null) {
        HS.Controller.PubSub.publish('ajax-redirect', location)
        //empty string defaults to login page with jump param to kick back to current page after login
        if (location === '') {
          var queryString = $.param({ jump: window.location.href })
          window.location.href = hsGlobal.path + 'members/login/?' + queryString
        } else {
          window.location.href = location
        }
        return true
      }
    }
    return false
  }

  function handleFail(response, status, error, channel) {
    'use strict'

    var result = false

    if (handleRedirect(response)) {
      result = true
    }

    var json = false
    var contentType = response.getResponseHeader('Content-Type')
    if (contentType && contentType.indexOf('application/json') > -1) {
      try {
        json = jQuery.parseJSON(response.responseText)
      } catch (e) {
        console.error(e)
      }
    }
    if (json && json.alerts) {
      HS.Utils.Main.error(json.alerts.join('\n'))
      result = true
    }
    if (json && json.errors) {
      if (
        HS.Controller.PubSub.hasSubscribers(channel ? channel : 'Ajax:Error')
      ) {
        HS.Controller.PubSub.publish(channel ? channel : 'Ajax:Error', json)
      } else {
        HS.Utils.Main.error(json.errors.join('<br />'))
      }
      result = true
    }
    if (json && _.isString(json.data)) {
      HS.Utils.Main.error(json.data)
    }
    return result
  }
  function handleDisplayFail(response) {
    var json = null
    try {
      json = JSON.parse(response.responseText)
    } catch (e) {
      console.error(e)
      return false
    }

    if (json && json.errors) {
      HS.Utils.Main.error(json.errors.join('<br />'))
    }
  }
  function getRequest(urlparts, callback, params) {
    var data = {}
    if ($.isPlainObject(urlparts)) {
      data.url = getUrl(urlparts.url)
      data.done = urlparts.done ? urlparts.done : null
      data.fail = handleFail
      data.params = urlparts.params ? urlparts.params : null

      if (urlparts.fail) {
        data.fail = urlparts.fail
      }
    } else {
      data.url = getUrl(urlparts)
      data.done = callback ? callback : null
      data.fail = handleFail
      data.params = params ? params : null
    }
    if (!data.dataType) {
      data.dataType = 'json'
    }
    return data
  }
  function getJsonRequest(urlparts, callback, params) {
    var data = getRequest(urlparts, callback, params)

    if (data.params && $.isPlainObject(data.params)) {
      data.params = JSON.stringify(data.params)
    }
    return data
  }
  function doRequest(type, contentType, data) {
    return $.ajax({
      dataType: data.dataType,
      url: data.url,
      type: type,
      beforeSend: function (request) {
        if (HS.csrfToken) {
          request.setRequestHeader('X-CSRF-Token', HS.csrfToken)
        }
      },
      contentType: contentType,
      data: data.params,
      processData: contentType === false ? false : true,
    })
      .done(data.done)
      .fail(data.fail)
  }
  return {
    /**
     * @private
     * @field
     * @static
     * @inner
     */
    $singleton: true,
    /**
     * @type        {Function}
     * @param       {Object}    response                        XHR response containing data
     * @param       {Number}    response.status                 response code returned from the ajax request
     * @param       {Function}  response.getResponseHeader      response code returned from the ajax request
     * @param       {Number}    status                          same as response.status (Double Confirm)
     * @returns     {Void}
     * @function
     * @public
     * @static
     * @inner
     */
    checkRedirect: function (response) {
      return handleRedirect(response)
    },
    getRedirect: function (xhr) {
      if (xhr && xhr.getResponseHeader) {
        return xhr.getResponseHeader('X-HS-REDIRECT')
      }
    },
    parseToJson: function (response) {
      var json = false
      var contentType = response.getResponseHeader('Content-Type')
      if (contentType && contentType.indexOf('application/json') > -1) {
        json = jQuery.parseJSON(response.responseText)
      }
      return json
    },
    url: function (pParts) {
      return getUrl(pParts)
    },
    fail: function (response, status, error, pubSubLabel) {
      return handleFail(response, status, error, pubSubLabel)
    },
    displayFail: function (response) {
      return handleDisplayFail(response)
    },
    upload: function (urlparts, callback, params) {
      return doRequest('POST', false, getRequest(urlparts, callback, params))
    },
    post: function (urlparts, callback, params) {
      return doRequest(
        'POST',
        'application/x-www-form-urlencoded',
        getRequest(urlparts, callback, params)
      )
    },
    postJSON: function (urlparts, callback, params) {
      return doRequest(
        'POST',
        'application/json',
        getJsonRequest(urlparts, callback, params)
      )
    },
    get: function (urlparts, callback, params) {
      return doRequest(
        'GET',
        'application/x-www-form-urlencoded',
        getRequest(urlparts, callback, params)
      )
    },
    del: function (urlparts, callback, params) {
      return doRequest(
        'DELETE',
        'application/x-www-form-urlencoded',
        getRequest(urlparts, callback, params)
      )
    },
    delJSON: function (urlparts, callback, params) {
      return doRequest(
        'DELETE',
        'application/json',
        getJsonRequest(urlparts, callback, params)
      )
    },
    put: function (urlparts, callback, params) {
      return doRequest(
        'PUT',
        'application/x-www-form-urlencoded',
        getRequest(urlparts, callback, params)
      )
    },
    putJSON: function (urlparts, callback, params) {
      return doRequest(
        'PUT',
        'application/json',
        getJsonRequest(urlparts, callback, params)
      )
    },
    patchJSON: function (urlparts, callback, params) {
      return doRequest(
        'PATCH',
        'application/json',
        getJsonRequest(urlparts, callback, params)
      )
    },
    parseJSON: function (response) {
      return $.parseJSON(response.responseText)
    },
  }
})

/**
 * @deprecated
 * @class
 * @description Used inconjunction with Handlebars templates
 * @type {Class}
 * @property {Object}   Cached
 * @property {Object}   Compiled
 * @public
 * @requires Handlebars
 * @requires Underscore/Underscore.string
 * @requires jQuery
 **/
HS.Utils.Templates = {
  cache: {},
  compiled: {},
  put: function (tplName, source) {
    if (_.isArray(source)) {
      source = source.join('')
    }
    this.cache[tplName] = source.trim()
  },
  getFromCache: function (tplName) {
    var cache = this.cache

    if (!_.has(cache, tplName)) {
      if ($(tplName).html()) {
        this.put(tplName, $(tplName).html().trim())
      } else {
        this.put(tplName, '')
      }
    }

    return cache[tplName]
  },
  get: function (tplName, data) {
    if (!_.has(this.compiled, tplName)) {
      var jstPrecompiledTpl = this.getFromJST(tplName)
      if (jstPrecompiledTpl) {
        this.compiled[tplName] = jstPrecompiledTpl
      } else {
        var html = this.getFromCache(tplName)
        this.compiled[tplName] = Handlebars.compile(html)
      }
    }

    var template = this.compiled[tplName]
    return template(data)
  },
  getFromJST: function (tplName) {
    var JST = window.JST
    if (JST) {
      return JST[tplName]
    }
  },
}

/**
 * @class
 * @description Modified observer pattern that requests permission to perform an action that listening responders
 *              can prevent the action from being performed.
 * @type {Class}
 * @property {Object} List
 * @public
 * @requires Underscore
 * @requires jQuery
 * @requires HS.Utils.String
 **/
HS.Utils.Guard = {
  list: {},

  /**
   * Request permission to perform a specific action.
   *
   * @param {string} action the key/name of the action requested.
   * @returns {Promise} A promise to either perform an action (`.then()`) or catch a failure (`.fail()`).
   */
  ifICan: function (action) {
    var args = Array.prototype.slice.call(arguments, 1),
      callbackStack = []

    _.each(this.getStack(action), function (responder) {
      var context = responder.context || null
      var callbackResult = responder.callback.apply(context, args)

      //returning false fails out
      if (callbackResult === false) {
        callbackStack.push($.Deferred().reject().promise())
      } else if (
        callbackResult &&
        _.isFunction(callbackResult.then) &&
        _.isFunction(callbackResult.fail)
      ) {
        callbackStack.push(callbackResult)
      }
    })

    return $.when.apply($, callbackStack)
  },

  /**
   * Add a listener to an action and respond with a value allowing or denying the action.
   *
   * @param {string} action The key/name of the action to listen to.
   * @param {function} callback The response (ultimately a boolean) to the requested action.
   */
  respondTo: function (action, callback, context) {
    if (!_.isFunction(callback)) {
      throw new Error(
        HS.Utils.String.sprintf(
          'Callback must be a Function that returns a boolean or a promise. Type "%s" given',
          $.type(callback)
        )
      )
    }
    this.getStack(action).push({
      callback: callback,
      context: context,
    })
  },

  stopResponding: function (action, callback, context) {
    var stack = this.getStack(action)

    // If it's an empty stack, don't bother with anything else
    if (!stack.length) {
      return
    }

    if (callback && context) {
      // Reverse loop per: https://gist.github.com/chad3814/2924672#file-gistfile1-js-L63-L71
      for (var i = stack.length - 1; i >= 0; i -= 1) {
        var responder = stack[i]

        if (responder.callback === callback && responder.context === context) {
          stack.splice(i, 1)
        }
      }
    } else if (callback) {
      for (var i = stack.length - 1; i >= 0; i -= 1) {
        var responder = stack[i]

        if (responder.callback === callback) {
          stack.splice(i, 1)
        }
      }
    } else {
      delete this.list[action]
    }
  },

  /**
   * Return the stack of listeners/responders for a specific action. If no current stack exists, create one and return it.
   *
   * @param {string} action
   * @returns {array}
   */
  getStack: function (action) {
    if (_.has(this.list, action)) {
      return this.list[action]
    }

    this.list[action] = []
    return this.list[action]
  },
}

HS.Utils.BBUtils = {
  startHistory: function (root) {
    var hasPushState = !!(window.history && window.history.pushState)
    var options = {
      root: root,
      pushState: hasPushState,
      silent: true,
    }

    Backbone.history.start(options)

    if (!hasPushState) {
      var fragment = window.location.pathname.substr(root.length)
      if (fragment && Backbone.history.getFragment() !== fragment) {
        Backbone.history.navigate('#' + fragment, { trigger: true })
      } else {
        Backbone.history.loadUrl(fragment)
      }
    } else {
      Backbone.history.loadUrl()
    }

    this.extendBackboneHistory()
  },

  extendBackboneHistory: function () {
    //Override Backbone.history.checkUrl to add route/fragment context
    Backbone.history.checkUrl = _.bind(function () {
      return Backbone.History.prototype.checkUrl.apply(this, arguments)
    }, Backbone.history)

    //Override Backbone.history.navigate to add route/fragment context
    Backbone.history.navigate = _.bind(function (fragment) {
      return Backbone.History.prototype.navigate.apply(this, arguments)
    }, Backbone.history)
  },
  getRoutePattern: function (fragment) {
    var handler = HS.Utils.BBUtils.getRouteHandler(fragment)
    if (!handler || !App.appRouter) {
      return
    }
    var pattern = null
    _.each(App.appRouter.appRoutes, function (val, key) {
      if (
        App.appRouter._routeToRegExp(key).toString() == handler.route.toString()
      ) {
        pattern = key + ''
      }
    })
    return pattern
  },

  getRouteHandler: function (fragment) {
    return _.find(Backbone.history.handlers, function (handler) {
      return handler.route.test(fragment)
    })
  },
  getCurrentRouterArgs: function () {
    var fragment = Backbone.history.getFragment()
    var currentHandler = this.getRouteHandler(fragment)
    var routerArgs = App.appRouter._extractParameters(
      currentHandler.route,
      fragment
    )
    return routerArgs
  },

  isCurrentRoute: function (routeName) {
    var routes = _.invert(App.appRouter.appRoutes)

    function routeMatches(routeName) {
      var route = routes[routeName]
      if (route) {
        var routeRegex = App.appRouter._routeToRegExp(route)
        return routeRegex.test(Backbone.history.getFragment())
      }
      return false
    }

    if (_.isArray(routeName)) {
      return _.any(routeName, routeMatches)
    } else {
      return routeMatches(routeName)
    }
  },
}

HS.Classes.Checkboxes = function Checkboxes(toggleAllID, pCheckboxSelector) {
  var mToggleAllID = toggleAllID,
    inputSelector = pCheckboxSelector,
    toggleAll = null,
    doSelect = function (isDoSelect) {
      var list = $(inputSelector)

      if (list) {
        var len = list.length,
          i

        for (i = 0; i < len; i++) {
          list[i].checked = isDoSelect

          if (isDoSelect) {
            $(toggleAll).trigger('Checkboxes.checkboxSelected', [list[i]])
          } else {
            $(toggleAll).trigger('Checkboxes.checkboxDeselected', [list[i]])
          }
        }
      }
    },
    determineChecked = function (event) {
      if (toggleAll.attr('checked')) {
        doSelect(true)
      } else {
        doSelect(false)
      }

      $(toggleAll).trigger('Checkboxes.toggleAllChanged', [toggleAll])
    },
    checkboxChanged = function (event) {
      var isChecked = toggleAll.is(':checked'),
        list = $(inputSelector),
        i = 0

      if (list) {
        var len = list.length

        if (isChecked) {
          for (i = 0; i < len; i++) {
            if (!list[i].checked) {
              toggleAll.attr('checked', false)
              break
            }
          }
        } else {
          var allChecked = true

          for (i = 0; i < len; i++) {
            if (!list[i].checked) {
              allChecked = false
              break
            }
          }

          if (allChecked) {
            toggleAll.attr('checked', true)
          }
        }

        if (allChecked) {
          toggleAll.attr('checked', true)
        }
      }
    }
  this.toString = function () {
    var list = $(inputSelector + ':checked'),
      temp = [],
      len = list.length,
      i = 0

    for (i = 0; i < len; i++) {
      temp.push(list[i].value)
    }

    return temp.join(',')
  }
  this.getSelectedCount = function () {
    return $(inputSelector + ':checked').length
  }
  this.reset = function () {
    toggleAll = $(mToggleAllID)

    if (toggleAll) {
      toggleAll.click(determineChecked)
    }

    $(pCheckboxSelector).click(checkboxChanged)
  }
  this.reset()
}

HS.Plugins.Flash = new Class(function () {
  return {
    $singleton: true,
    main: function () {
      $.noty.defaults = {
        layout: 'inline',
        theme: 'defaultTheme',
        type: 'alert',
        text: '',
        dismissQueue: true, // If you want to use queue feature set this true
        template:
          '<div data-cy="notyMessage" class="noty_message"><span class="noty_text"></span><div class="noty_close"></div></div>',
        animation: {
          open: { opacity: 1.0 },
          close: { opacity: 0 },
          easing: 'swing',
          speed: 300, // opening & closing animation speed
        },
        timeout: 8000, // delay for closing event. Set false for sticky notifications
        force: false, // adds notification to the beginning of queue when set to true
        modal: false,
        maxVisible: 5, // you can set max visible notification for dismissQueue true option
        closeWith: ['button'], // ['click', 'button', 'hover']
        callback: {
          onShow: function () {
            HS.Plugins.Flash.closeDuplicates(this)

            $('.noty_message').click(function (e) {
              //.noty_close has its own handler, short circuit if that is what was clicked
              if ($(e.target).is('.noty_close')) {
                return
              }
              // close notys if the message container was clicked
              $(e.currentTarget).find('.noty_close').click()
            })
          },
          afterShow: function () {},
          onClose: function () {},
          afterClose: function () {},
        },
        buttons: false, // an array of buttons
      }
    },
    close: function (id) {
      if (id) {
        if (_.isObject(id) && id.options && id.options.id) {
          id = id.options.id
        }
        $.noty.close(id)
      } else {
        $.noty.closeAll()
      }
    },
    /**
     * Close a preexisting noty object without a fade-out
     *
     * @param {Object} noty
     */
    fastClose: function (noty) {
      if (_.isObject(noty)) {
        noty.close()
        noty.closeCleanUp()
      }
    },
    closeDuplicates: function (notyObject) {
      // If there are other noty objects with the same type and text
      // as the one we're trying to show, close them.
      // Also, if we're trying to show a 'success' noty,
      // close all other notys with type 'error'
      _.each($.noty.store, function (noty) {
        if (noty.options.id === notyObject.options.id) {
          return
        }

        // Trim HTML Tags from Noty text before comparing
        var notyText = noty.options.text.replace(/<[^>]*>/gi, '')
        var notyObjectText = notyObject.options.text.replace(/<[^>]*>/gi, '')

        if (
          (notyText === notyObjectText &&
            noty.options.type === notyObject.options.type) ||
          (notyObject.options.type === 'success' &&
            noty.options.type === 'error')
        ) {
          noty.$bar.hide()
          noty.close()
        }
      })
    },
    error: function (message, options) {
      options = _.extend(
        {
          text: message,
          type: 'error',
          timeout: 8000,
        },
        options
      )

      return $('#notyContainer').noty(options)
    },
    mesg: function (message, seconds, options) {
      if (typeof seconds === 'object') {
        options = seconds
      }
      options = _.extend({ text: message, type: 'alert' }, options)
      if (seconds) {
        options.timeout = seconds * 1000
      } else {
        options.timeout = 8000
      }

      return $('#notyContainer').noty(options)
    },
    warning: function (message, options) {
      options = _.extend(
        {
          text: message,
          type: 'warning',
          timeout: 8000,
        },
        options
      )
      return $('#notyContainer').noty(options)
    },
    success: function (message, options = {}) {
      options = _.extend(
        {
          text: message,
          type: options.type || 'success',
          //Default to 6 seconds so that "Undo" links don't outlive their actual window
          timeout: 6000,
          template:
            '<div class="noty_message" data-cy="notyMessage"><i class="icon-check"></i><span class="noty_text"></span><div class="noty_close"></div></div>',
        },
        options
      )
      return $('#notyContainer').noty(options)
    },
    okMessage: function (message, yesCallback, options) {
      var okText = 'OK'

      if (typeof options === 'object') {
        if ('ok' in options) {
          okText = options.ok
        }
      }
      this.confirm(message, yesCallback, false, {
        buttons: [
          {
            addClass: 'btn btn-success',
            text: okText,
            onClick: function ($noty) {
              $noty.close()
              if (yesCallback) {
                yesCallback()
              }
            },
          },
        ],
      })
    },
    confirmDelete: function (message, yesCallback, noCallback, options) {
      var deleteText = 'Delete'
      if (options && options.deleteText) {
        deleteText = options.deleteText
      }

      var baseButtonClass = 'btn'
      var okClass = `${baseButtonClass} btn-success`
      if (options && options.okClass) {
        okClass = `${baseButtonClass} ${options.okClass}`
      }

      this.confirm(message, yesCallback, noCallback, {
        buttons: [
          {
            addClass: okClass,
            text: deleteText,
            onClick: function ($noty) {
              $noty.yesClicked = true
              $noty.close()
              yesCallback()
            },
          },
          {
            addClass: 'btn btn-link',
            text: 'Cancel',
            onClick: function ($noty) {
              $noty.noClicked = true
              $noty.close()
            },
          },
        ],
      })
    },
    /**
     * This method basically reverses the button/text link. Normally, we use a cancel link
     * and an OK button. But this method will reverse that to use a cancel button
     * and an OK text link.
     */
    confirmWithOkTextCancelButton: function (
      message,
      yesCallback,
      noCallback,
      options
    ) {
      if (typeof message === 'object') {
        options = message
        message = options.text
      } else if (yesCallback && typeof yesCallback === 'object') {
        options = yesCallback
      }

      // first set up the defaults
      var okText = 'OK'
      var cancelText = 'Cancel'

      if (typeof options === 'object') {
        if ('ok' in options) {
          okText = options.ok
        }
        if ('cancel' in options) {
          cancelText = options.cancel
        }
      }
      options = _.extend(
        {
          text: message,
          type: 'confirm',
          timeout: false,
          modal: true,
          animation: {
            open: { opacity: 1.0 },
            close: { opacity: 1.0 },
            easing: 'swing',
            speed: 175, // opening & closing animation speed
          },
          callback: {
            onShow: function () {
              $('#notyContainer,.noty_modal').addClass('fade in')
            },
            afterShow: function () {
              this.$bar.find('button:first').focus()
              var close = $.proxy(this.close, this)
              $('.noty_modal').click(close)
              HS.stack.opened('ConfirmBox', function () {
                close()
              })
            },
            onClose: function () {
              HS.stack.closed('ConfirmBox', function () {})
              $('#notyContainer,.noty_modal').removeClass('fade in')
            },
          },
          buttons: [
            {
              addClass: 'btn btn-link',
              text: okText,
              onClick: function ($noty) {
                $noty.close()
                if (yesCallback) {
                  yesCallback()
                }
              },
            },
            {
              addClass: 'btn btn-success',
              text: cancelText,
              onClick: function ($noty) {
                if (noCallback) {
                  noCallback()
                }
                $noty.close()
              },
            },
          ],
        },
        options
      )

      return $('#notyContainer').noty(options)
    },
    confirm: function (message, yesCallback, noCallback, options) {
      if ($.noty && $.noty.confirmOpen === true) {
        return
      }
      if (typeof message === 'object') {
        options = message
        message = options.text
      } else if (yesCallback && typeof yesCallback === 'object') {
        options = yesCallback
      }

      // first set up the defaults
      var okText = 'OK'
      var okClass = 'btn-success'
      var cancelText = 'Cancel'

      if (typeof options === 'object') {
        if ('ok' in options) {
          okText = options.ok
        }
        if ('okClass' in options) {
          okClass = options.okClass
        }
        if ('cancel' in options) {
          cancelText = options.cancel
        }
      }

      function closeOnNav() {
        if (window.location.hash !== MODAL_HASH && _.isFunction(this.close)) {
          this.close()
        }
      }

      options = _.extend(
        {
          text: message,
          type: 'confirm',
          timeout: false,
          modal: true,
          animation: {
            open: { opacity: 1.0 },
            close: { opacity: 1.0 },
            easing: 'swing',
            speed: 175, // opening & closing animation speed
          },
          callback: {
            onShow: function () {
              $.noty.confirmOpen = true
              $('#notyContainer,.noty_modal').addClass('fade in')
            },
            afterShow: function () {
              this.$bar.find('button:first').focus()
              var close = $.proxy(this.close, this)
              $('.noty_modal').click(close)
              HS.stack.opened('ConfirmBox', function () {
                close()
              })
              const { pathname, search } = window.location
              window.history.replaceState(
                null,
                null,
                pathname + search + MODAL_HASH
              )
              this.closeOnNav = () => {
                if (
                  window.location.hash !== MODAL_HASH &&
                  _.isFunction(this.close)
                ) {
                  this.close()
                }
              }
              $(window).on('hashchange', this.closeOnNav)
            },
            onClose: function () {
              $.noty.confirmOpen = false
              HS.stack.closed('ConfirmBox', function () {})
              $('#notyContainer,.noty_modal').removeClass('fade in')
              this.yesClicked || this.noClicked || (noCallback && noCallback())
              history.replaceState(null, null, ' ')
              $(window).off('hashchange', this.closeOnNav)
            },
          },
          buttons: [
            {
              addClass: 'btn ' + okClass,
              text: okText,
              onClick: function ($noty) {
                $noty.yesClicked = true
                $noty.close()
                if (yesCallback) {
                  yesCallback()
                }
              },
            },
            {
              addClass: 'btn btn-link',
              text: cancelText,
              onClick: function ($noty) {
                $noty.noClicked = true
                if (noCallback) {
                  noCallback()
                }
                $noty.close()
              },
            },
          ],
        },
        options
      )

      return $('#notyContainer').noty(options)
    },
  }
})

HS.Plugins.SliderButton = new Class(function () {
  function setHiddenInputValue() {
    if (this.slider.hasClass('active')) {
      // button is turning off
      this.hiddenInput.val(this.disabledValue)
    } else {
      // button is turning on
      this.hiddenInput.val(this.enabledValue)
    }
    this.hiddenInput.change()
  }
  return {
    constructor: function (
      sliderEl,
      hiddenInputEl,
      disabledValue,
      enabledValue
    ) {
      this.slider = $(sliderEl)
      this.hiddenInput = $(hiddenInputEl)
      this.disabledValue =
        typeof disabledValue !== 'undefined' ? disabledValue : 0
      this.enabledValue = typeof enabledValue !== 'undefined' ? enabledValue : 1

      this.slider.click($.proxy(setHiddenInputValue, this))
    },
  }
})

/**
 * DO NOT REMOVE!
 *
 * We don't use this helper directly.
 * But it's here for developers to use on their custom apps (in customer-sidebar)
 */
Handlebars.registerHelper('encode', function (text) {
  return encodeURIComponent(text)
})

HS.Plugins.EmailPicker = new Class(function () {
  var ccLimit = 25
  var dataDuration = 30
  var textBoxListConfig = {
    unique: true,
    bitsOptions: {
      editable: {
        addOnBlur: true,
      },
    },
    inBetweenEditableBits: false,
    uniqueInsensitive: true,
    startEditableBit: false,
    plugins: {
      autocomplete: {
        placeholder: null,
        maxResults: 25,
        minLength: 1,
        focusFirst: true,
      },
    },
  }

  function addEmailToList(data) {
    if (data && data.id == this.$el.attr('id')) {
      this.addEmail(data.email)
      this.trigger('addEmail', [data.email])
    }
  }

  function removeEmailFromList(data) {
    var elemId = this.$el.attr('id')

    if (data && data.id == elemId) {
      this.removeEmail(data.email)
      this.trigger('removeEmail', [data.email])
    }
  }

  return {
    /**
     *
     * @param el            element
     * @param emails        selected emails
     * @param extraOptions  additional autocomplete options
     */

    constructor: function (el, emails, extraOptions) {
      this.$el = $(el)
      this.extraOptions = extraOptions || []

      this.textboxList = new $.TextboxList(this.$el, textBoxListConfig)
      this.textboxList.addEvent('bitBoxRemove', $.proxy(this.removeBit, this))
      this.textboxList.addEvent(
        'bitBoxAdd',
        $.proxy(function (bit) {
          this.addBit(bit, $.proxy(this.addEmail, this))
        }, this)
      )

      this.textboxList.addEvent(
        'bitEditableBlur',
        $.proxy(function () {
          this.editableBlur($.proxy(this.addEmail, this))
        }, this)
      )

      if (emails) {
        if (typeof emails === 'string') {
          emails = emails.split(',')
        }
        this.textboxList.setValues(emails)
      }

      this.container = this.$el.siblings('.textboxlist')
      this.input = this.container.find('input')
      this.input
        .keyup(fixAutoCompletePositioning)
        .on('paste', triggerBitProcessing)
      this.loadEmailsFromStorage()
      this.subId = HS.Controller.PubSub.subscribe(
        'CcBcc:update',
        $.proxy(this.saveEmailsToStorage, this)
      )

      this.subAddEmailId = HS.Controller.PubSub.subscribe(
        'CcBcc:addEmail',
        $.proxy(addEmailToList, this)
      )
      this.subRemEmailId = HS.Controller.PubSub.subscribe(
        'CcBcc:removeEmail',
        $.proxy(removeEmailFromList, this)
      )
    },

    destroy: function () {
      HS.Controller.PubSub.unsubscribe('CcBcc:update', this.subId)
      HS.Controller.PubSub.unsubscribe('CcBcc:addEmail', this.subAddEmailId)
      HS.Controller.PubSub.unsubscribe('CcBcc:removeEmail', this.subRemEmailId)
      HS.Utils.LocalStorage.stopListening(
        'CCBCC:Emails',
        this.onCcBccEmailChange
      )
      this.$el.remove()
      this.textboxList.destroy()
    },

    clearEmails: function () {
      this.$el.val('')
    },
    loadEmailsFromStorage: function () {
      var that = this

      this.onCcBccEmailChange = _.bind(function (key, action) {
        if (action === 'deleted') {
          this.ajaxCcBcc()
        }
      }, this)
      HS.Utils.LocalStorage.listenKeyChange(
        'CCBCC:Emails',
        this.onCcBccEmailChange
      )

      var emailArr = HS.Utils.LocalStorage.get('CCBCC:Emails')

      if (!emailArr) {
        this.ajaxCcBcc()
      } else {
        emailArr = emailArr.concat(this.extraOptions)
        var emails = _.map(emailArr, function (email) {
          return [email, email, email, email]
        })

        this.setAutocomplete(emails)
      }
    },
    ajaxCcBcc: function () {
      var that = this

      HS.Utils.Ajax.get('/api/v0/company/cc-bcc-emails', function (data) {
        var emailCount = data.length,
          emails = new Array(emailCount)

        for (var j = 0; j < emailCount; j++) {
          emails[j] = [data[j], data[j], data[j], data[j]]
        }

        HS.Utils.LocalStorage.set('CCBCC:Emails', data, {
          TTL: (this.dataDuration + 5) * 60000,
        })

        that.setAutocomplete(emails)
      })
    },
    saveEmailsToStorage: function () {
      if (this.$el && this.$el.length) {
        var emailArr = HS.Utils.LocalStorage.get('CCBCC:Emails') || []

        var arr = this.getValue()

        for (var i = 0; i < arr.length; i++) {
          var email = arr[i].trim()
          if (
            HS.Utils.String.isValidEmail(email) &&
            emailArr.indexOf(arr[i].trim()) === -1
          ) {
            emailArr.push(arr[i].trim())
          }
        }

        HS.Utils.LocalStorage.set('CCBCC:Emails', emailArr, {
          TTL: (dataDuration + 5) * 60000,
        })
      }
    },
    setAutocomplete: function (emails) {
      if (this.textboxList) {
        var autocomplete = this.textboxList.plugins.autocomplete
        autocomplete.setValues(emails)
      }
    },
    removeEmail: function (email) {
      this.container
        .find(
          '.textboxlist-bits .textboxlist-bit[title="' +
            email +
            '"] a.textboxlist-bit-box-deletebutton'
        )
        .click()
      // blur it so that focus isn't set on the next one
      _.defer($.proxy(this.textboxList.blur, this.textboxList))
    },

    addEmail: function (s1, s2, s3) {
      if (!s2) {
        s2 = s1
      }

      if (!s3) {
        s3 = s1
      }

      this.textboxList.add(s1, s2, s3)
      this.textboxList.update()
    },
    removeBit: function () {
      // since there is no way to pass parameters to remove event handler, must provide other means to
      // differentiate behavior. In this case, don't clean up cc limit message this.skipClearError == true
      if (this.skipClearError !== true) {
        HS.Utils.clearInlineError(this.$el)
        this.skipClearError = false
      }
      this.hitLimit = false
    },

    //remove bit without triggering clear limit Error event
    removeBitSilently: function (bit) {
      this.skipClearError = true
      bit.remove()
      this.skipClearError = false
    },

    addBit: function (bit, addMethod) {
      var email = bit.getValue(),
        len,
        i,
        trimmedEmail

      //cap at ccLimit max email addresses
      if (this.textboxList.index && this.textboxList.index.length > ccLimit) {
        // use this.hitLimit to make sure we only publish event once since will be called (n - 25) times
        if (!this.hitLimit) {
          HS.Utils.setInlineError(
            this.$el,
            'This field allows a maximum of ' + ccLimit + ' emails per message.'
          )
        }

        this.removeBitSilently(bit)
        this.hitLimit = true
      } else {
        this.hitLimit = false
        var unescapedEmail = string.unescapeHTML(email[1])

        //handle copy/paste list
        if (
          string.include(unescapedEmail, ';') ||
          string.include(unescapedEmail, ' ') ||
          string.include(unescapedEmail, ',')
        ) {
          var emailArr = unescapedEmail.split(/[\s;,]{1,}/)

          //must remove this bit before call add for the full array
          bit.remove()
          _.each(emailArr, function (email) {
            trimmedEmail = string.trim(email)

            trimmedEmail = HS.Utils.String.parseEmailFromCopy(trimmedEmail)

            if (HS.Utils.String.isValidEmail(trimmedEmail)) {
              addMethod(trimmedEmail, trimmedEmail, trimmedEmail)
            }
          })
        }
        var parsedEmail = HS.Utils.String.parseEmailFromCopy(unescapedEmail)
        if (HS.Utils.String.isValidEmail(parsedEmail)) {
          if (parsedEmail !== unescapedEmail) {
            // bit.setValue() is whacky, so let's just remove and re-add the parsed Email
            bit.remove()
            addMethod(parsedEmail)
          }
        } else {
          bit.remove()
        }
      }
    },
    editableBlur: function (addMethod) {
      var option = this.container.find(
        '.textboxlist-autocomplete-results .textboxlist-autocomplete-result-focus'
      )

      if (option.length) {
        var email = option.text()

        addMethod(email, email, email)
        option.removeClass('textboxlist-autocomplete-result-focus')
      }
    },
    getValue: function () {
      var val = this.$el.val()
      var array = val.split(/[\s;,]{1,}/)
      if (array && array.length == 1) {
        if (string.isBlank(array[0])) {
          array = []
        }
      }
      return array
    },
    focus: function () {
      this.input.focus()
    },
  }

  function triggerBitProcessing(e) {
    var input = $(this)
    setTimeout(function () {
      //escape the expression in case they paste in garbage or XSS stuff
      input.val(Handlebars.Utils.escapeExpression(input.val())).blur().focus()
    }, 1)
  }

  function fixAutoCompletePositioning() {
    var inputBit = $(this).parent()
    var autoCompleteBox = $(this)
      .closest('.textboxlist')
      .find('.textboxlist-autocomplete')

    autoCompleteBox.css({
      left: inputBit.position().left + 'px',
      top: inputBit.position().top + inputBit.height() + 4 + 'px',
      width: 'auto',
    })
  }
})

HS.Utils.setInlineError = function (inputEl, message) {
  var el = $(inputEl)
  var errorEl = '<span id="" class="help-block">' + message + '</span>'
  el.siblings('.help-block').remove()
  el.parent().append(errorEl)
  el.closest('.control-group').addClass('error')
}

HS.Utils.clearInlineError = function (inputEl) {
  $(inputEl).siblings('.help-block').remove()
  $(inputEl).closest('.control-group').removeClass('error')
}

HS.Utils.Main = new Class({
  $singleton: true,
  // Split array into chunks
  //
  // version: 810.114
  // discuss at: http://phpjs.org/functions/array_chunk
  // +   original by: Carlos R. L. Rodrigues (http://www.jsfromhell.com)
  // *     example 1: array_chunk(['Kevin', 'van', 'Zonneveld'], 2);
  // *     returns 1: {0 : {0: 'Kevin', 1: 'van'} , 1 : {0: 'Zonneveld'}}
  arrayChunk: function (input, size) {
    for (var x, i = 0, c = -1, l = input.length, n = []; i < l; i++) {
      ;(x = i % size) ? (n[c][x] = input[i]) : (n[++c] = [input[i]])
    }

    return n
  },
  addCsrfHeader: function (xhr) {
    if (HS.csrfToken) {
      xhr.setRequestHeader('X-CSRF-Token', HS.csrfToken)
    }
  },
  sortNumAsc: function (int1, int2) {
    if (int1 == int2) {
      return 0
    }
    return int1 > int2 ? 1 : -1
  },

  sortAsc: function (a, b) {
    if (typeof a === 'string') {
      return HS.Utils.Main.sortStrAsc(a, b)
    }
    return HS.Utils.Main.sortNumAsc(a, b)
  },
  sortDesc: function (a, b) {
    if (typeof a === 'string') {
      return HS.Utils.Main.sortStrDesc(a, b)
    }
    return HS.Utils.Main.sortNumDesc(a, b)
  },
  sortNumDesc: function (int1, int2) {
    return -HS.Utils.Main.sortNumAsc(int1, int2)
  },
  sortStrAsc: function (str1, str2) {
    if (!str1 || typeof str1 !== 'string') {
      str1 = ''
    }
    if (!str2 || typeof str2 !== 'string') {
      str2 = ''
    }
    return str1.toLowerCase().localeCompare(str2.toLowerCase())
  },
  sortStrDesc: function (str1, str2) {
    return -HS.Utils.Main.sortStrAsc(str1, str2)
  },
  uploadFile: function (file, url, options) {
    var config = _.extend(
      {
        maxFileSize: 2097152,
        fileKey: 'file',
      },
      options
    )

    if (!file) {
      return jQuery.Deferred().resolve()
    }

    // HTML5 properties - `file.size` will be missing on IE and older browsers
    if (file.size && file.size > config.maxFileSize) {
      var formattedSize = HS.Utils.Main.formatSize(file.size)
      HS.Plugins.Flash.error(
        'This image is too large, please upload an image smaller than ' +
          formattedSize +
          '.'
      )
      return jQuery.Deferred().reject()
    }

    var formData = new FormData()
    formData.append(config.fileKey, file)

    // Use the common Ajax.upload method so we handle CSRF token properly
    return HS.Utils.Ajax.upload(url, null, formData)
  },
  /**
   * Creates a hidden iframe and form to submit an HTTP request
   * that downloads the file passed in the URL.
   */
  downloadFile: function (url, method) {
    var $iframe = $('<iframe>', {
      name: 'AjaxDownloaderIFrame',
    })
      .hide()
      .appendTo('body')

    var $form = $('<form>', {
      action: url,
      method: method || 'POST',
      target: 'AjaxDownloaderIFrame',
    }).appendTo('body')

    $form.submit()
    $form.remove()
  },
  removeDocsFromMenu: function () {
    $('#docsNav').remove()
    $('#header ul.dropdown-menu a.docs').parent().remove()
    $('.js-docs-report').remove()
  },
  cursorLoading: function () {
    $('body').addClass('loading')
  },
  cursorDefault: function () {
    $('body').removeClass('loading')
  },
  getQueryParams: function (queryString) {
    var query = (queryString || window.location.search).substring(1)
    if (!query) {
      return false
    }
    return _.chain(query.split('&'))
      .map(function (params) {
        var p = params.split('=')
        return [p[0], decodeURIComponent(p[1])]
      })
      .object()
      .value()
  },

  clearTags: function () {
    HS.Utils.LocalStorage.deleteKey('Tags-List')
  },
  getTags: function (callback, force) {
    if (!$.isFunction(callback)) {
      force = callback
      callback = undefined
    }
    var TAGS_LIST_CHECKSUM = 'Tags-List-Checksum'

    var shouldFetchCachedTagsList = function (checksum) {
      var prevChecksum = HS.Utils.LocalStorage.get(TAGS_LIST_CHECKSUM)
      return checksum === prevChecksum
    }

    var handleGetTags = _.bind(function (data) {
      var tagChecksum = data.tagChecksum
      if (shouldFetchCachedTagsList(tagChecksum)) {
        return callback && callback(HS.Utils.LocalStorage.get('Tags-List'))
      }
      HS.Utils.LocalStorage.set(tagChecksum, tagChecksum)
      return this.getTagsList(callback, force)
    }, this)
    return HS.Utils.Ajax.get(`/api/v0/tags/checksum`).done(handleGetTags)
  },
  getTagsList: function (callback, force) {
    var tags = HS.Utils.LocalStorage.get('Tags-List')
    if (tags && !force) {
      callback && callback(tags)
    } else {
      return HS.Utils.Ajax.get('/api/v0/tags', function (json) {
        var timeToLive = (5 + 85 * Math.min(1, json.tags.length / 400)) * 60000
        HS.Utils.LocalStorage.set('Tags-List', json.tags, { TTL: timeToLive })
        callback && callback(json.tags)
      }).fail(function (err) {
        callback && callback([], true)
      })
    }
  },
  getSearchModifiers: function (callback, force) {
    if (!$.isFunction(callback)) {
      force = callback
      callback = undefined
    }
    var modifiers = HS.Utils.LocalStorage.get('Modifiers-List')
    if (modifiers && !force) {
      callback && callback(modifiers)
    } else {
      return HS.Utils.Ajax.get('/api/v0/search/modifiers', function (json) {
        var timeToLive = 10 * 60000 // 10 minutes
        HS.Utils.LocalStorage.set('Modifiers-List', json.modifiers, {
          TTL: timeToLive,
        })
        callback && callback(json.modifiers)
      }).fail(function (err) {
        callback && callback({}, true)
      })
    }
  },

  setLocalDraft: function (ticketNumber, data) {
    HS.Utils.LocalStorage.set('Draft:' + ticketNumber, data, {
      TTL: 24 * 60 * 60000,
    })
  },
  getLocalDraft: function (ticketNumber) {
    return HS.Utils.LocalStorage.get('Draft:' + ticketNumber)
  },
  clearLocalDraft: function (ticketNumber) {
    HS.Utils.LocalStorage.deleteKey('Draft:' + ticketNumber)
  },
  scrollToTop: function () {
    $('html, body').animate({ scrollTop: 0 }, 'fast')
  },
  removeWindowSelection: function () {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty()
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges()
      }
    } else if (document.selection && document.selection.empty) {
      // IE?
      document.selection.empty()
    }
  },

  clearPromises: function (promises) {
    //TODO make this accept single arg, array, or multiple args, add test
    if (_.isArray(promises)) {
      _.each(promises, function (promise) {
        promise && promise.abort && promise.abort()
      })
    }
  },

  //taken from ___ snap, I lost the link... Someone on stack overflow had a similar method to this which I stole - bjones
  insertAtCaret: function (textArea, text) {
    textArea = $(textArea)[0]
    var scrollPos = textArea.scrollTop
    var strPos = 0
    var strEnd = 0
    var br =
      textArea.selectionStart || textArea.selectionStart == '0'
        ? 'ff'
        : document.selection
        ? 'ie'
        : false
    if (br == 'ie') {
      textArea.focus()
      var range = document.selection.createRange()
      range.moveStart('character', -textArea.value.length)
      strEnd = strPos = range.text.length
    } else if (br == 'ff') {
      strPos = textArea.selectionStart
      strEnd = textArea.selectionEnd
    }

    var front = textArea.value.substring(0, strPos)
    var back = textArea.value.substring(strEnd, textArea.value.length)
    textArea.value = front + text + back
    strPos = strPos + text.length
    if (br == 'ie') {
      textArea.focus()
      var range = document.selection.createRange()
      range.moveStart('character', -textArea.value.length)
      range.moveStart('character', strPos)
      range.moveEnd('character', 0)
      range.select()
    } else if (br == 'ff') {
      textArea.selectionStart = strPos
      textArea.selectionEnd = strPos
      textArea.focus()
    }
    textArea.scrollTop = scrollPos
  },
  replaceCurlyBraces: function (str) {
    if (str) {
      return str.replace(/&#123;/g, '{').replace(/&#125;/g, '}')
    }
    return str
  },
  replaceHtmlComments: function (str) {
    if (str) {
      return str.replace(/%3C%21--/g, '<!--').replace(/--%3E/g, '-->')
    }
    return str
  },
  isMobileDevice: function () {
    return /(iPhone|iPod|iPad|Android)/i.test(navigator.userAgent)
  },
  isAppleDevice: function () {
    return navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false
  },
  isIPhone: function () {
    return /iPhone/i.test(navigator.platform)
  },
  getIOSVersion: function () {
    var iOSVersion = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
    if (iOSVersion) {
      return {
        major: parseInt(iOSVersion[1], 10),
        minor: parseInt(iOSVersion[2], 10),
        patch: parseInt(iOSVersion[3] || 0, 10),
      }
    }

    return false
  },
  openUrlSafely: function (event) {
    // This method fixes this bug/issue:
    // https://secure.helpscout.net/conversation/45682417/100398/
    event.preventDefault()

    var elem = $(event.target)
    if (elem.not('a')) {
      elem = elem.closest('a')
    }
    if (elem.length) {
      var popup = window.open(elem.attr('href'), '_blank')
      popup.opener = null
    }
  },
  encode: function (theVal) {
    if (encodeURIComponent) {
      return encodeURIComponent(theVal)
    } else return escape(theVal)
  },
  // http://kevin.vanzonneveld.net
  // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // +   improved by: Nick Callen
  // +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // +   improved by: Sakimori
  // +   bugfixed by: Michael Grier
  // *     example 1: wordwrap('Kevin van Zonneveld', 6, '|', true);
  // *     returns 1: 'Kevin |van |Zonnev|eld'
  // *     example 2: wordwrap('The quick brown fox jumped over the lazy dog.', 20, '<br />\n');
  // *     returns 2: 'The quick brown fox <br />\njumped over the lazy<br />\n dog.'
  // *     example 3: wordwrap('Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.');
  // *     returns 3: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod \ntempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim \nveniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea \ncommodo consequat.'
  // PHP Defaults
  wordwrap: function (str, int_width, str_break, cut) {
    var m = arguments.length >= 2 ? arguments[1] : 75,
      b = arguments.length >= 3 ? arguments[2] : '\n',
      c = arguments.length >= 4 ? arguments[3] : false,
      i,
      j,
      l,
      s,
      r

    str = str.toString()

    if (m < 1) {
      return str
    }

    for (i = -1, l = (r = str.split(/\r\n|\n|\r/)).length; ++i < l; r[i] += s) {
      for (
        s = r[i], r[i] = '';
        s.length > m;
        r[i] += s.slice(0, j) + ((s = s.slice(j)).length ? b : '')
      ) {
        j =
          c == 2 || (j = s.slice(0, m + 1).match(/\S*(\s)?$/))[1]
            ? m
            : j.input.length - j[0].length ||
              (c == 1 && m) ||
              j.input.length + (j = s.slice(m).match(/^\S*/)).input.length
      }
    }

    return r.join('\n')
  },
  submitPostForm: function (url) {
    var theForm = [
      '<form action="',
      url,
      '" method="post">',
      '<input type="hidden" name="authToken" value="',
      HS.csrfToken,
      '"></form>',
    ].join('')
    $(theForm).appendTo('body').submit()
  },
  focusOnFirstElement: function () {
    $('form:not(.filter) :input:visible:enabled:first').focus()
  },
  timeago: function (el) {
    jQuery.timeago.settings.strings = {
      prefixAgo: null,
      prefixFromNow: null,
      suffixAgo: 'ago',
      suffixFromNow: 'from now',
      seconds: '1 min',
      minute: '1 min',
      minutes: '%d mins',
      hour: '1 hr',
      hours: '%d hrs',
      day: 'a day',
      days: '%d days',
      month: 'about a month',
      months: '%d months',
      year: 'about a year',
      years: '%d years',
      numbers: [],
    }
    var $el = $(el || 'abbr.timeago')

    // If the $el result set have any preexisting tooltip $tip's, remove them!
    $el.tooltip('destroy')

    // Add a tooltip, with the current text as the tooltip
    $el.tooltip({
      title: $el.text(),
      placement: 'bottom',
      container: '#tkContent',
    })

    // Copy the timestamp to the title to support the legacy timeago library
    $el.attr('title', $el.data('timestamp'))
    $el.timeago()

    // Remove the title so that the browser doesn't use its own tooltip in
    // addition to our own - no double tooltip
    $el.removeAttr('title')
  },
  fancyboxDefaults: {
    type: 'iframe',
    transitionOut: 'none',
    transitionIn: 'none',
    padding: 0,
    titleShow: false,
    enableEscapeButton: false,
  },
  initPopups: function (parent) {
    if (!parent) {
      parent = document
    }
    HS.Utils.Main.initPopupList($(parent).find('a.fancyNoTitle,a.fancy'))
    HS.Utils.Main.initPopupList($(parent).find('a.fancyInline'), {
      type: 'inline',
    })
    HS.Utils.Main.initPopupList($(parent).find('a.fancyimg'), {
      type: 'image',
      autoDimensions: false,
    })
  },
  initPopupList: function (links, opts) {
    $(links).each(function (index, el) {
      var uniqueId = Math.floor(Math.random() * 10000) + 1 + '-' + index
      var options = _.defaults({}, opts, HS.Utils.Main.fancyboxDefaults)

      var cssClass = $(el).attr('class')
      if (cssClass) {
        var theClasses = cssClass.split(' '),
          clen = theClasses.length,
          x

        for (x = 0; x < clen; x++) {
          var classPart = theClasses[x].trim(),
            parts = classPart.split(':')

          if (classPart === 'fancy' || classPart === 'fancyframe') {
            continue
          }

          if (parts.length === 2) {
            switch (parts[0]) {
              case 'width':
                if (parts[1].indexOf('%') === -1) {
                  options.width = parseInt(parts[1], 10)
                } else {
                  options.width = parts[1]
                }
                break

              case 'height':
                if (parts[1].indexOf('%') === -1) {
                  options.height = parseInt(parts[1], 10)
                } else {
                  options.height = parts[1]
                }
                break
            }
          }
        }
      }
      if ($(el).hasClass('js-no-scroll')) {
        options.noScroll = true
      }
      options.wrapClass = $(el).data('wrapClass')

      options.onComplete = function ($el, i, opts) {
        HS.Utils.Main.onFancyShow(uniqueId, opts)
      }
      options.onCleanup = $.proxy(
        HS.Utils.Main.onFancyHide,
        this,
        uniqueId,
        options
      )

      $(el).fancybox(options)
    })
  },
  onFancyShow: function (id, opts) {
    $('.redactor-link-tooltip').remove()
    HS.Utils.Main.onModalOpen(opts)
    HS.stack.opened(
      'Modal-popup-' + id,
      function () {
        $.fancybox.close()
        parent && parent.$ && parent.$.fancybox.close()
      },
      null,
      window
    )
    if (opts.type == 'iframe') {
      $('#fancybox-wrap').addClass('iframe-wrap')
    } else {
      $('#fancybox-wrap').removeClass('iframe-wrap')
    }
    if (opts.wrapClass) {
      $('#fancybox-wrap').addClass(opts.wrapClass)
    }
    const { pathname, search } = window.location
    window.history.replaceState(null, null, pathname + search + MODAL_HASH)
    this.closeOnNav = () => {
      if (
        window.location.hash !== MODAL_HASH &&
        parent &&
        parent.$ &&
        parent.$.fancybox
      ) {
        parent.$.fancybox.close()
      }
    }
    $(window).on('popstate', this.closeOnNav)
  },
  destroyFancy: function (el) {
    $(el).unbind('click.fb')
  },
  getContentDocument: function (iframe) {
    iframe = $(iframe)[0]
    var doc = iframe.contentDocument
    if (!doc) {
      doc = iframe.document
    }
    if (!doc) {
      doc = iframe.contentWindow.document
    }
    return doc
  },
  inIframe: function () {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  },
  onFancyHide: function (id, opts) {
    HS.Utils.Main.onModalClose('fancy')
    HS.stack.closed('Modal-popup-' + id)
    $('#fancybox-wrap').removeClass('iframe-wrap')
    if (opts && opts.wrapClass) {
      $('#fancybox-wrap').removeClass(opts.wrapClass)
    }
    history.replaceState(null, null, ' ')
    $(window).off('hashchange', this.closeOnNav)
  },
  popup: function (options, id) {
    if (id === undefined) {
      id = 'directCall'
    }

    var opts = _.defaults({}, options, HS.Utils.Main.fancyboxDefaults, {
      onComplete: function ($el, i, opts) {
        HS.Utils.Main.onFancyShow(id, opts)
      },
      onCleanup: $.proxy(HS.Utils.Main.onFancyHide, this, id),
    })
    $.fancybox(opts)
  },
  popupClose: function (parent) {
    var context = parent ? parent : window
    context.$.fancybox.close()
  },
  onModalOpen: function (opts) {
    if (!opts || !opts.noScroll) {
      $('body').addClass('overflow-hidden')
    }
  },
  onModalClose: function () {
    $('body').removeClass('overflow-hidden')
  },
  initFormPopovers: function () {
    if ($.fn.popover) {
      $('.formHelper').popover({
        placement: 'right',
        trigger: 'focus',
      })
    }
  },
  isRedirect: function (xmlObj) {
    var exception = xmlObj.getResponseHeader('APP-MESSAGE'),
      redirect = xmlObj.getResponseHeader('APP-REDIRECT')

    if (exception && !string.isBlank(exception)) {
      alert(exception)
    }

    if (redirect && !string.isBlank(redirect)) {
      window.location = redirect
      return true
    }

    return false
  },
  redirectToUrl: function (theUrl) {
    window.location = theUrl
  },
  setupAllNoneCheckboxes: function (block) {
    $(block + ' a.selAll').click(function () {
      $(block).find(':checkbox').prop('checked', true)
      return false
    })
    $(block + ' a.selNone').click(function () {
      $(block).find(':checkbox').prop('checked', false)
      return false
    })
  },
  clickableRows: function (pTableRowPath, pAnchorPath) {
    var list = $(pTableRowPath),
      len = list.length,
      i,
      mouseOverCB = function () {
        $(this).css('cursor', 'pointer')
      }

    for (i = 0; i < len; i++) {
      var theRow = $(list[i]),
        link = $(theRow).find(pAnchorPath)

      if (link.length == 1) {
        link = link[0]
        var theFunc = new Function(
            'document.location.href="' + link.href + '"'
          ),
          cells = theRow.find('td:not(:first)')

        if (cells) {
          var clen = cells.length,
            x,
            theCell

          for (x = 0; x < clen; x++) {
            theCell = $(cells[x])
            theCell.click(theFunc)
            theCell.mouseover(mouseOverCB)
          }
        }
      }
    }
  },
  initPopovers: function (el, options) {
    if (!el) {
      el = $('[data-toggle=popover]')
    }
    var opts = _.extend(
      {
        delay: { show: 0, hide: 0 },
      },
      options
    )
    if ($.fn.popover) {
      el.popover(opts)
    }
  },
  disablePopovers: function (el) {
    !el && (el = $('[rel=popover]'))
    $(el).popover('disable')
  },
  enablePopovers(el) {
    !el && (el = $('[rel=popover]'))
    $(el).popover('enable')
  },
  removePopovers: function (el) {
    !el && (el = $('.popover'))
    $(el).remove()
  },
  initTooltips: function (el, options) {
    if (!el) {
      el = $('[rel=tooltip]')
    }
    var opts = _.extend(
      {
        delay: { show: 0, hide: 0 },
        trigger: 'hover',
      },
      options
    )
    if ($.fn.tooltip) {
      // destroy any preexisting tooltips on these elements
      el.tooltip('destroy')
      el.tooltip(opts)
      el.on('click', function () {
        $(this).tooltip('hide')
      })
    }
  },
  disableTooltips: function (el) {
    !el && (el = $('[rel=tooltip]'))
    $(el).tooltip('disable')
  },
  removeTooltips: function (el) {
    !el && (el = $('.tooltip'))
    var $el = $(el)
    $el.tooltip('destroy')
    $el.remove()
  },

  chosenWidget: function (select, options) {
    var parent = select.parent().removeClass('dropdown')

    var selectId = select.attr('id')
    var opts = _.defaults(options, {
      callback: function (id) {
        console.log('selected', id)
      },
      name: 'Select One', //can be HTML
      title: '',
      tooltipPlacement: 'bottom',
    })

    select
      .data('placeholder', opts.name)
      .chosen()
      .change(function () {
        var selectedId = $(this).val()
        opts.callback(selectedId)
        select.val('').trigger('liszt:updated')
        return false
      })

    //don't lose tooltip when using chosen
    var chosenLink = parent.find('.chzn-default')

    if (opts.title) {
      chosenLink.attr({
        rel: 'tooltip',
        'data-placement': opts.tooltipPlacement,
        'data-original-title': opts.title,
      })

      HS.Utils.Main.initTooltips(chosenLink)
      chosenLink.mousedown(function () {
        //if we are open, trigger mouseout when clicking the chosen link
        if (chosenSelect && chosenSelect.hasClass('chzn-container-active')) {
          $(this).trigger('mouseout')
        }
      })
    }

    parent.find('a.chzn-single').removeClass('chzn-single')
    //chozen takes select#assign-select and produces #assign_select_chzn
    var chosenLinkId = selectId.replace(/\-/gi, '_') + '_chzn'
    var chosenSelect = parent.find('#' + chosenLinkId)

    HS.Utils.chosenEscapeSetup(select, 'Chosen-Select-' + selectId)

    HS.Utils.chosenCssTransition(select)

    chosenSelect.find('a:first').empty().prepend(opts.name)
    return chosenSelect
  },

  /*
   * This dropdown plugin only leverages the menu of chosen but keeps the original select on the UI.
   * It creates a wrapper element around the select box and places the trigger on top of it with CSS.
   * It also takes checks if the number of items in the list is greater than the maxItems option, and
   * if not, it leaves the select box unchanged.
   */
  chosenInline: function (select, opts) {
    var $wrapper
    var options = _.defaults({}, opts, {
      display: 'inline-block',
      maxItems: 15,
    })

    if (select.find('option').length > options.maxItems) {
      $wrapper = $('<div class="chzn-inline-wrapper"></div>')
      $wrapper.css({ display: options.display })
      select.wrap($wrapper).chosen().show()
      select.on('change', function () {
        $(this).blur()
      })
      select.on('focus', function (e) {
        e && e.preventDefault()
        $(this).trigger('liszt:open')
      })
      HS.Utils.chosenEscapeSetup(select, 'ChosenInline')
      HS.Utils.chosenCssTransition(select)
    }

    return select
  },

  /*
   * Generates a chosen-like filter component based on a Dropdown (not a <select>).
   * Use this when you want to add a filter <input> field to a Dropdown with lots of list items (+15).
   */
  dropdownFilter: function ($dropdowns, userOptions) {
    var options = _.defaults({}, userOptions, {
      categoryClass: null,
      categoryDividerClass: null,
      ignoreFilterClass: null,
      maxItems: 15,
    })
    var shouldCategoryBeVisible = function ($category, query) {
      var $nextItem = $category.next()
      var visibleItems = 0
      while ($nextItem) {
        visibleItems +=
          $nextItem.text().toLowerCase().indexOf(query) !== -1 ? 1 : 0
        $nextItem = $nextItem.next()
        if (
          !$nextItem.length ||
          $nextItem.hasClass(options.categoryDividerClass)
        ) {
          $nextItem = false
        }
      }
      return visibleItems > 0
    }
    $dropdowns.each(function (index) {
      var $dropdown = $($dropdowns[index])
      var $listItems = $dropdown.find('li')
      var $input
      var timeoutID
      if (
        $listItems.length <= options.maxItems ||
        $dropdown.siblings('.dropdown-filter').length > 0
      ) {
        return
      }
      $input = $('<input type="text" class="dropdown-filter" />')
      $input.click(function () {
        return false
      }) // Prevents the dropdown from closing
      $input.keyup(function () {
        var query = $(this).val().toLowerCase()
        if (query === '') {
          return $listItems.show()
        }
        $listItems.each(function () {
          var $anchor = $(this).find('a')
          var text = $(this).text().toLowerCase()
          if ($(this).hasClass(options.categoryClass)) {
            var visible = shouldCategoryBeVisible($(this), query)
            $(this).toggle(visible)
          } else if ($(this).hasClass(options.categoryDividerClass)) {
            var visible = shouldCategoryBeVisible($(this).next(), query)
            $(this).toggle(visible)
          } else {
            $(this).toggle(
              $anchor.hasClass(options.ignoreFilterClass) ||
                text.indexOf(query) !== -1
            )
          }
        })
        // If the first visible element is a divider, hide it.
        $visibleItems = $listItems.filter(function () {
          return $(this).css('display') !== 'none'
        })
        if (
          $visibleItems.length > 0 &&
          $visibleItems.first().hasClass(options.categoryDividerClass)
        ) {
          $visibleItems.first().hide()
        }
      })
      $dropdown.before($input)
      $dropdown
        .parents('.dropdown')
        .on('shown.bs.dropdown', function () {
          var $input = $(this).find('.dropdown-filter')
          timeoutID = setTimeout(function () {
            $input.trigger('focus')
          }, 0)
        })
        .on('hidden.bs.dropdown', function () {
          clearTimeout(timeoutID)
        })
      var stackName = $dropdown.attr('id') || $dropdown.attr('class')
      HS.Utils.dropdownEscapeSetup($dropdown.parents('.dropdown'), stackName)
      return $dropdown
    })
    return $dropdowns
  },

  isNumeric: function (pVal) {
    return pVal - 0 == pVal && pVal.length > 0
  },
  url: function (pContextPath, pParts) {
    return pContextPath + pParts.join('/') + '/'
  },
  formatNumber: function (number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(',', '').replace(' ', '')

    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
      dec = typeof dec_point === 'undefined' ? '.' : dec_point,
      s,
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec)

        return '' + Math.round(n * k) / k
      }

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }

    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }

    return s.join(dec)
  },
  wysiwyg: function (theObj) {},
  formatSize: function (size) {
    if (size >= 1073741824) {
      size = HS.Utils.Main.formatNumber(size / 1073741824, 2, '.', '') + ' GB'
    } else {
      if (size >= 1048576) {
        size = HS.Utils.Main.formatNumber(size / 1048576, 2, '.', '') + ' MB'
      } else {
        if (size >= 1024) {
          size = HS.Utils.Main.formatNumber(size / 1024, 0) + ' KB'
        } else {
          return '1 KB'
        }
      }
    }

    return size
  },
  /**
   * Do not change this function name. It's embedded into our
   * sample conversations that we inject into a mailbox
   * when a company first registers.
   */
  openKeyboardShortcutsModal: function () {
    $('#keybindFrame').trigger('click')
  },
  actionFlash: function (message, pOptions) {
    return HS.Plugins.Flash.mesg(message, 10, pOptions)
  },
  closeFlash: function () {
    return HS.Plugins.Flash.close()
  },
  flash: function (message, options) {
    return HS.Plugins.Flash.mesg(message, 4, options)
  },
  error: function (message, options) {
    return HS.Plugins.Flash.error(message, options)
  },
  warning: function (message, options) {
    return HS.Plugins.Flash.warning(message, options)
  },
  success: function (message, options) {
    return HS.Plugins.Flash.success(message, options)
  },
  successMuted: function (message, options) {
    options = _.extend({ type: 'alert' }, options)
    return HS.Plugins.Flash.success(message, options)
  },
  successWithUndo: function (message, options, callback) {
    var msg = message

    if (this.prevNoty) {
      HS.Plugins.Flash.close()
      $('#notyContainer li').hide()
    }

    this.prevNoty = this.success(msg, { ...options, undo: true })
    $('#notyContainer a.js-undo').on('click', callback)
  },

  confirmDelete: function (message, yesCallback, noCallback, options) {
    return HS.Plugins.Flash.confirmDelete.apply(this, arguments)
  },
  confirm: function (message, yesCallback, noCallback, options) {
    return HS.Plugins.Flash.confirm.apply(this, arguments)
  },
  setBreadcrumbArrow: function (baseLink, baseText, levelText) {
    $('#topBc')
      .removeClass('padd')
      .html(
        [
          '<span class="arrow"><a href="',
          baseLink,
          '">',
          baseText,
          '</a></span> ',
          levelText,
        ].join('')
      )
  },
  resetBreadcrumb: function (baseText) {
    $('#topBc')
      .addClass('padd')
      .html('<span>' + baseText + '</span>')
  },

  // is a link pointing at hs-app
  isHSLink: function (href) {
    if (!_.isString(href)) return false
    return (
      href.indexOf(window.location.origin + hsGlobal.path) === 0 ||
      href.indexOf(hsGlobal.path) === 0
    )
  },

  unsetUndefinedAttrs: function (object) {
    _.each(object, function (val, key) {
      if (val === undefined) delete object[key]
    })
    return object
  },

  stripTags: function (html) {
    var tmp = document.createElement('DIV')
    tmp.innerHTML = sanitizeHTML(html)

    return tmp.textContent || tmp.innerText
  },

  hasImage: function (text) {
    var temp = document.createElement('DIV')
    temp.innerHTML = sanitizeHTML(text)
    return $(temp).find('img').length > 0
  },
  replaceLinks: function (html) {
    return html.replace(
      /<a([^<>]*?)href=['"]([^<>]*?)['"]([^<>]*?)?>(.*?)?<\/a>/gi,
      function (match, x, href, y, name) {
        var text = name
        if (href && href.length && href !== name) {
          text += ' (' + href + ')'
        }
        return text
      }
    )
  },
  isJsonValid: function (json, status) {
    var isValid = false

    if (json && status) {
      if (status === 'success') {
        if (json.success) {
          isValid = true
        } else if (json.error) {
          HS.Utils.Main.error(json.error)
        }
      }
    } else if (json && json.success) {
      isValid = true
    }

    return isValid
  },

  prevent: function (e) {
    var state = HS.Controller.StateMachine.modules[this.moduleName].state
    if (
      HS.keyboardGlobal.state !== 'Dropdown' &&
      state !== 'Closed' &&
      state !== 'Status'
    ) {
      e.preventDefault()
      e.stopImmediatePropagation()
      return false
    }
  },

  /**
   * Given an array of memberIds and an optional list of members, return the list of member objects found for the ids
   * @param {Array} memberIds
   * @param {Array=[]} members (optional)
   */
  getMemberObjects: function (memberIds, members) {
    if (!members) {
      members =
        appData.members ||
        (appData.dropdowns && appData.dropdowns.assign
          ? appData.dropdowns.assign
          : [])
    }
    var results = _.filter(
      _.map(
        memberIds,
        $.proxy(function (memberId) {
          var object = _.find(members, function (member) {
            return member.id == memberId
          })
          if (object) {
            if (!object.name) {
              object.name = object.title
            }
          }
          return object
        }, this)
      ),
      function (member) {
        return member
      }
    )

    return results
  },

  addProtocolToInvalidLinks: function (links) {
    // sometimes emails contain links that are not valid. For instance:
    // <a style="text-decoration: underline" href=www.letscarpool.govt.nz>www.letscarpool.govt.nz</a>
    // When user tries to click on link above, the browser change the href to something like:
    // https://secure.helpscout.net/conversation/4753651/12333/www.letscarpool.govt.nz
    // since it doesn't start with a valid protocol.
    // This code will find hrefs that are missing a valid (including custom) protocol, and prepend http://
    // It should still support custom protocols such as custom://my.link/whatever

    $(links).each(function () {
      var href = $(this).attr('href')
      //has an href but it doesn't have a protocol
      //Also make sure that hrefs with no protocol (i.e. /mailbox/12346789) don't get messed with
      if (
        href &&
        href.indexOf('/') !== 0 &&
        !/^\w+:\/\//.test(href) &&
        !/^mailto:/i.test(href) &&
        !/^tel:/i.test(href)
      ) {
        href = 'http://' + href
      }
      $(this).attr('href', href)
    })
  },

  // Prevent concurrent ajax calls on a particular model, instead queueing each call to `save`/`destroy` and running them sequentially
  // This should prevent duplicate drafts (for example), as long as only a single model instance is in use at a time.
  // See SequentialSyncModelSpec.js for more details on expected behavior
  sequentialSyncModel: function (Model) {
    function getWrappedMethod(method) {
      return function () {
        if (!this._steps) {
          this._steps = []
        }
        var currentArgs = arguments.length
          ? Array.prototype.slice.call(arguments, 0)
          : []

        var deferred = new $.Deferred()
        var doMethod = _.bind(function () {
          this._promise =
            method.apply(this, currentArgs) || new $.Deferred().resolve()
          this._promise
            .then(function () {
              deferred.resolve.apply(deferred, arguments)
            })
            .fail(function () {
              deferred.reject.apply(deferred, arguments)
            })
          if (this._abortingSync) {
            this._promise.abort()
          }
          return this._promise
        }, this)
        this._steps.push(doMethod)
        //execute first call immediately
        if (this._steps.length === 1) {
          this._executeNextStep()
        }

        return deferred.promise()
      }
    }
    Model.prototype.save = getWrappedMethod(Model.prototype.save)
    Model.prototype.destroy = getWrappedMethod(Model.prototype.destroy)
    Model.prototype.fetch = getWrappedMethod(Model.prototype.fetch)

    Model.prototype._executeNextStep = function () {
      if (this._steps.length) {
        var step = this._steps[0]
        //always go to next step whether fail or success
        step().always(_.bind(this._onStepComplete, this))
      } else {
        if (this._abortingSync) {
          //once all the current steps have been executed, consider aborting complete
          delete this._abortingSync
        }
        delete this._promise
      }
    }
    Model.prototype._onStepComplete = function () {
      this._steps.splice(0, 1)
      this._executeNextStep()
    }

    Model.prototype.abortSync = function () {
      if (this._promise) {
        this._abortingSync = true
        this._promise.abort()
      }
    }
  },

  //This helper is used in conjunction with HS.Utils.Main.timeago() to create jquery timeago elements
  timeagoHelper: function (date, dateOnly, showTimeAlways) {
    var CURRENT_YEAR_DATE_FORMAT = 'MMM D'
    var DATE_FORMAT = "MMM D, 'YY"
    var separator = ', '
    var timeFormat = hsGlobal && hsGlobal.timeFormat == 2 ? 'H:mm' : 'h:mma' // 24h vs 12h
    var humanFormat = 'ddd MMM D, YYYY [at] ' + timeFormat
    var humanFormatWithoutYear = 'ddd MMM D [at] ' + timeFormat
    //could be a Handlebars object - look for a literal true
    showTimeAlways = showTimeAlways === true

    //If dateOnly isn't passed in with handlebars: {{timeago somedate}} you get
    //false positives if you do a if (dateOnly) so this check is to avoid that
    dateOnly = dateOnly === true
    //Parse the dates in as local time zone so time ago is from the users time. This avoids having to set it when you format
    date = moment.utc(date).tz(hsGlobal.timezone)
    var today = moment.utc().tz(hsGlobal.timezone)
    var isToday = false
    var isCurrentYear = false
    if (date.year() == today.year()) {
      humanFormat = humanFormatWithoutYear
      isCurrentYear = true
      if (moment(today).isSame(date, 'day')) {
        isToday = true
      }
    }
    var timeago = null
    if (isToday && !dateOnly) {
      timeago =
        '<abbr class="timeago" data-timestamp="' +
        date.format(moment.defaultFormat) +
        '">' +
        date.format(humanFormat) +
        '</abbr>'
    } else {
      var format = null
      if (isToday) {
        format = timeFormat
      } else {
        if (isCurrentYear) {
          format = CURRENT_YEAR_DATE_FORMAT
        } else {
          format = DATE_FORMAT
        }

        //by default, if it isn't today
        if (showTimeAlways || (!dateOnly && today.diff(date, 'hours') <= 24)) {
          format += separator + ' ' + timeFormat
        }
      }
      timeago = '<abbr>' + date.format(format) + '</abbr>'
    }

    return timeago
  },
  abort: function (promise) {
    if (promise && _.isFunction(promise.abort)) {
      promise.abort()
    }
  },
  abortAll: function (promises) {
    if (promises && promises.constructor === Array) {
      _.each(
        promises,
        function (promise) {
          this.abort(promise)
        },
        this
      )
    }
  },
  wistiaEmbed: function (id, options) {
    var options = options || {}
    _.defaults(options, {
      version: 'v1',
      videoWidth: 472,
      videoHeight: 266,
      controlsVisibleOnLoad: true,
      playerColor: '8D8E8E',
      autoPlay: false,
    })

    if (!window.Wistia) {
      return HS.Utils.injectJs(
        'https://fast.wistia.com/static/concat/E-v1.js',
        _.bind(HS.Utils.Main.wistiaRender, this, id, options),
        { charset: 'ISO-8859-1' },
        10000
      )
    } else {
      return HS.Utils.Main.wistiaRender(id, options)
    }
  },
  wistiaRender: function (id, config) {
    return Wistia.embed(id, config)
  },
})

$(function () {
  if (!window.console || !window.console.log) {
    window.console = {
      log: function () {},
      error: function () {},
      debug: function () {},
    }
  }
  HS.Utils.Main.initFormPopovers()
  var tooltip = $('[rel=tooltip]')
  if (tooltip.length && $.fn.tooltip) {
    $('[rel=tooltip]').tooltip({
      delay: { show: 0, hide: 0 },
    })
  }
  if (tooltip.length) {
    $('body').on('click', '.dropdown-toggle, .dropdown', function () {
      $('.tooltip').remove()
    })
  }
  $('body').on('mousedown', '#tagLink', function () {
    $('.tooltip').remove()
  })

  var tooltipRemove = function () {
    $('.tooltip').remove()
    $('.dropdown:not(.open)')
      .off('click', tooltipRemove)
      .on('click', tooltipRemove)
  }

  $('.dropdown:not(.open)').on('click', tooltipRemove)

  if (HS.Controller.PubSub && HS.Controller.PubSub.subscribe) {
    HS.Controller.PubSub.subscribe('User:Dropdown:CloseAll', function () {
      HS.stack.closeAll(function (channelName) {
        return channelName.indexOf('Dropdown:') === 0
      }, true)

      $('.dropdown.open').removeClass('open')
      //click to close any *open* redactor dropdowns (they have a class of .dropact)
      $('a.dropact').click()
    })

    HS.Controller.PubSub.subscribe('App:Editor:Loaded', function () {
      $('.dropdown')
        .off('click', tooltipRemove)
        .on('click', function () {
          $('.tooltip').remove()
        })
    })
  }

  //in ipad safari (at least), anchor tags with no href attribute trigger navigation to {{url}}/undefined, regardless of e.preventDefault();
  if (HS.Utils.Main.isMobileDevice()) {
    function setHrefOnAnchorTags() {
      $('a:not([href])').attr('href', 'javascript:void(0)')
    }
    setInterval(setHrefOnAnchorTags, 1000)
  }

  //if a dropdown opens and it's opens bigger than the current scroll window, scroll down see it all.
  $(document).on('show.bs.dropdown', '.dropdown.with-popper', function (e) {
    if (e.namespace === 'bs.dropdown') {
      const $parent = $(e.target)
      const $popperContainer = $parent.find('.popperContainer')
      const $menu = $parent.find('.dropdown-menu')

      if (e.target && $popperContainer[0]) {
        const placementY = $menu.hasClass('pull-right') ? 'end' : 'start'
        const placementX = $parent.hasClass('dropup') ? 'top' : 'bottom'

        // if within sidenav, limit the dropdown within the container
        const $boundary = $parent.closest('#sidebar-lt, #sidebar-rt')
        const boundaryOption =
          $boundary.length > 0
            ? [
                {
                  name: 'preventOverflow',
                  options: {
                    padding: 8,
                    boundary: $boundary[0],
                  },
                },
              ]
            : []

        const popperInstance = createPopper(e.target, $popperContainer[0], {
          placement: `${placementX}-${placementY}`,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
            {
              name: 'flip',
              enabled: true,
              options: {
                fallbackPlacements: [
                  `top-${placementY}`,
                  `bottom-${placementY}`,
                ],
              },
            },
            ...boundaryOption,
          ],
        })

        // Update its position
        popperInstance.update()
        $parent.data('popperInstance', popperInstance)
      }

      return
    }
  })

  $(document).on('hide.bs.dropdown', '.dropdown.with-popper', function (e) {
    if (e.namespace === 'bs.dropdown') {
      var $parent = $(e.target)
      var menu = $parent.find('.dropdown-menu')

      const popper = $parent.data('popperInstance')
      const popperContainer = $parent.find('.popperContainer')
      popperContainer.removeClass('open')

      if (popper && popper.destroy) popper.destroy()

      return
    }
  })

  //if a dropdown opens and it's opens bigger than the current scroll window, scroll down see it all.
  $(document).on('show.bs.dropdown', '.dropdown', function (e) {
    if (e.namespace === 'bs.dropdown') {
      var $window = $(window)
      var $parent = $(e.target)
      var menu = $parent.find('.dropdown-menu')

      if ($parent.hasClass('with-popper')) {
        return
      }

      var offsetThreshold = {
        bottom: 30,
        top: 20,
      }
      if (!menu.length) {
        return
      }
      var roughlyMenuBottom =
        $parent.offset().top +
        $parent.height() +
        menu.height() +
        offsetThreshold.bottom
      var windowBottom = $window.height() + $window.scrollTop()
      var dropUpMenuTop =
        $parent.offset().top - menu.height() - offsetThreshold.top

      //dropping down would cut off the bottom AND dropping up would NOT cut off the top
      var dropup =
        roughlyMenuBottom > windowBottom && dropUpMenuTop > $window.scrollTop()
      $parent.toggleClass('dropup', dropup)
    }
  })

  //Main nav - set up all nav dropdowns with keyboard/escape stack
  $('.nav-main li.dropdown, .nav-secondary li.dropdown').each(function () {
    HS.Utils.dropdownEscapeSetup(
      $(this),
      $(this).attr('id') || $(this).attr('class')
    )
  })
})

HS.Utils.LocalStorage = LocalStorage
HS.Utils.SessionStorage = SessionStorage
HS.Constants = Constants
HS.Plugins.Keyboard = Keyboard

Controller.PubSub.subscribe('User:Keyboard:Escape', function () {
  return HS.stack.executeStack()
})
//shortcut cuz this is a brutally long name
HS.keyboardGlobal = Controller.StateMachine.modules.KeyboardGlobal

window.HS = HS
export default HS
